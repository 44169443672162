/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'gatsby';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { validEmail } from '../utils/form';
import Layout from '../components/layout';
import '../styles/Pages/forgot_password/styles.scss';
import Spinner from '../images/spinner.svg';
import { SERVER_URL } from '../utils/environment';
import { getLocale, setLanguage } from '../utils/locale';
import LanguageSelector from '../components/LanguageSelector';
import InkblotLogo from '../images/custom/logos/inkblot-logo-wordmark.png';

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      loading: false,
      sent: false,
      retry: false,
      second: 0,
      validated: false,
      error: '',
    };

    this.timer = null;
  }

  componentDidMount() {
    const params = queryString.parse(window.location.search);
    if (!params.lang) return;
    if (params.lang !== 'fr' || params.lang !== 'en') return;
    this.clearUrlParam();
    setLanguage(params.lang);
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  clearUrlParam = () => {
    const baseUrl = window.location.href.split('?')[0];
    window.history.replaceState({}, '', baseUrl);
  };

  setTimer = () => {
    const { second } = this.state;

    if (second > 0) {
      this.setState({ second: second - 1 });
      this.timer = setTimeout(() => this.setTimer(), 1000);
    }
  };

  handleChange = event => {
    const { value } = event.target;

    if (value) {
      if (validEmail.test(value)) {
        this.setState({ validated: true, error: '' });
      } else {
        this.setState({
          validated: false,
          error: (
            <FormattedMessage
              id="error.emailMustBeValid"
              defaultMessage="Email must be valid"
            />
          ),
        });
      }
    }

    this.setState({ email: value, sent: false });
  };

  forgotPassword = () => {
    this.setState({ loading: true, sent: false });
    axios
      .post(`${SERVER_URL}/api/v2/forgot_passwords`, {
        email: this.state.email,
      })
      .then(() => {
        this.setState(
          {
            loading: false,
            sent: true,
            second: 60,
            retry: false,
          },
          () => this.setTimer(),
        );
      })
      .catch(() => {
        this.setState({ loading: false });
      });
  };

  handleSubmit = event => {
    const { second, validated } = this.state;
    event.preventDefault();

    if (!validated) return;

    if (second === 0) {
      this.forgotPassword();
    } else {
      this.setState({ loading: false, retry: true });
    }
  };

  render() {
    const { loading, email, sent, second, retry, error } = this.state;

    return (
      <Layout location={this.props.location}>
        <div className="forgot-password" role="main">
          <div className="password-header">
            <img src={InkblotLogo} alt="inkblot logo" />
            <LanguageSelector />
          </div>
          <div className="password-container">
            <div>
              <div>
                <h1>
                  <FormattedMessage
                    id="auth.forgotMyPassword"
                    defaultMessage="Forgot Password"
                  />
                </h1>
                <h3>
                  <FormattedMessage
                    id="auth.passwordInstructions"
                    defaultMessage="Enter your email to receive a password reset link."
                  />
                </h3>
              </div>
              <form onSubmit={this.handleSubmit}>
                <label htmlFor="email">
                  <div style={{ display: 'flex' }}>
                    <FormattedMessage id="auth.email" defaultMessage="Email" />
                    <div
                      style={{
                        color: 'red',
                        fontSize: '24px',
                        marginLeft: '8px',
                      }}
                    >
                      *
                    </div>
                  </div>
                  <input
                    placeholder="Enter email"
                    id="email"
                    name="email"
                    type="text"
                    onChange={this.handleChange}
                  />
                </label>
                <p role="alert" className="error-message">
                  {error}
                </p>

                <div className="fp-button-wrapper">
                  <button className="fp-button" type="submit">
                    <FormattedMessage
                      id="auth.sendPasswordReset"
                      defaultMessage="SEND PASSWORD RESET"
                    />
                  </button>
                  <p>
                    <span className="fp-sign-in">
                      <FormattedMessage
                        id="auth.alreadyHaveYourPassword"
                        defaultMessage="Already have your password?"
                      />
                      {getLocale() === 'fr' ? <br /> : ' '}
                      <Link title="Sign In" to="/?signin=true">
                        <FormattedMessage
                          id="auth.signIn"
                          defaultMessage="Sign In"
                        />
                      </Link>
                    </span>
                  </p>
                </div>
              </form>
            </div>
            {loading && (
              <p>
                <span className="spinner">
                  <img src={Spinner} alt="loading" />
                </span>
                <FormattedMessage
                  id="auth.sending"
                  defaultMessage="Sending..."
                />
              </p>
            )}
            {sent && !(second > 0 && retry) && (
              <p role="alert">
                <FormattedMessage
                  id="auth.ifAccountExists"
                  values={{ email: <b>{email}</b> }}
                />
              </p>
            )}
            {second > 0 && retry && (
              <p role="alert" aria-live="polite">
                <FormattedMessage
                  id="auth.alreadyRequestedPasswordReset"
                  values={{ second: <b>{second}</b> }}
                />
              </p>
            )}
          </div>
        </div>
      </Layout>
    );
  }
}

ForgotPassword.propTypes = {
  location: PropTypes.object.isRequired,
};

export default ForgotPassword;
